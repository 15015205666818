.pageList{
  cursor: pointer;
  /* padding-left: 12px; */
}

.pagination{
  display: flex;
  justify-content: center;

}

.pagination li{
  display:inline-block;
  padding: 0px 6px;
  cursor: pointer;
  border:none;
  border-radius:0.2rem;
  font-size: 20px;
  transition: 700ms; 
  box-shadow: 0.5px 0.5px 0.5px 0.5px black;
  font-family: Lato;
  margin:0px 1px;
}

.addUserBtn {
  padding:10px 15px;
  text-align:center;
  border-radius:0.2rem;
  border:none;
  background-color:#0d47a1;
  color:white;
  transition: 500ms;  
  cursor: pointer;
  font-family: Open ; 
  font-size: 16px;
  margin:25px 1px;
}

.addUserBtn a{
  text-decoration: none;
  color:white;
}

.addUserBtn:hover{
  background-color:#1976d2
}

.pagination li:hover{
  background-color: #0d47a1 
}

.pageContainer{
  display: flex;
  justify-content: center
}

.changePageButtonActive{
  background-color: #0d47a1;
  border-radius:0.3rem;
  color: white;
  padding: 5px 8px;
}

.changePageButtonDisabled{
  background-color: grey;
  border-radius:0.3rem;
  color: white;
  padding: 5px 8px;
}

.editBtn{
  text-align:center;
  border:none;
  align-items: center;
  border-radius:0.2rem;
  background-color:#0d47a1;
  color:white;
  transition: 500ms;  
  cursor: pointer;
  font-family: Lato;
  font-size: 12px; 
  margin:0px 4px;;
}

.editBtn:hover{
  background-color:#1976d2
}


.active {
  background-color:#0d47a1;
  color:white;
}

ul.pagination{
  padding: 0;
}

.tooltip {
  position: relative;
  display: inline-flex;
}

.tooltip .tooltiptext{
  visibility: hidden;
  width: 90px;
  background-color: #0d47a1;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -20px;
  left: -32px;
}

.tooltip .tooltiptextuser{
  visibility: hidden;
  width: 50px;
  background-color: #0d47a1;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -20px;
  left: -12px;
}



.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .tooltiptextuser {
  visibility: visible;
}

.selectList {
  border:none;
  cursor: pointer;
  flex:1;
  padding:10px 0;
}
.selectList:hover{
  background-color: rgb(199, 199, 199);
}

.containerSelectRental{
  display: flex;
  margin:5px 0;
}
