.bmsIdButton {
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.bmsIdButton:hover{
    font-size: 13px;
    transition: 150ms;
    font-weight: bold;
    text-shadow: 2px 3px 6px rgba(3, 4, 5, 0.45)
}