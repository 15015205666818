
.batteryDetailContainer{
    display: flex;
    justify-content: flex-start;
    margin-right: 40px;
}

.dateGpsContainer {
    display:flex;
    justify-content: flex-start;
    
}

.dateContainer{
    display: flex;
    flex-direction: column;
    margin-right: 50px;
}