.containerAddUser{
  display:flex;
  margin:20px;
  justify-content:center;
}

.titleAddUser{
  display:flex;
  justify-content: center;
  font-size: 24px;
  color:white;
}

.titleModal{
  display:flex;
  justify-content: center;
  font-size: 24px;
}

.buttonContainer{
  display: flex;
  justify-content: center;
  margin:20px;
}

.containerSelect{
  width: 100%;
  display:flex;
  justify-content: center
}